<template>
  <div id="feedback" v-bind:class="{ active: feedbackVisible }" v-on-click-outside="onClickOutside">
    <div class="floating-actions">
      <button class="floating-button background-color-ui-primary"
              :data-tip="tip"
              :aria-label="tip"
              type="button"
              @click="toggle"
      >
        <span class=material-icons v-if="!feedbackVisible">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M424-320q0-81 14.5-116.5T500-514q41-36 62.5-62.5T584-637q0-41-27.5-68T480-732q-51 0-77.5 31T365-638l-103-44q21-64 77-111t141-47q105 0 161.5 58.5T698-641q0 50-21.5 85.5T609-475q-49 47-59.5 71.5T539-320H424Zm56 240q-33 0-56.5-23.5T400-160q0-33 23.5-56.5T480-240q33 0 56.5 23.5T560-160q0 33-23.5 56.5T480-80Z"/></svg>
        </span>
        <span class="material-icons" v-else>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
        </span>
      </button>
    </div>
    <div id="feedback-modal" :style="`height: ${height + modalHeaderHeight}px`">
      <div class="modal-header">
        <template v-if="lang === 'fr'">
          <div class="modal-header-heading">Bienvenue,</div>
          <div class="modal-header-message">comment pouvons-nous vous aider aujourd'hui?</div>
        </template>
        <template v-else>
          <div class="modal-header-heading">Welcome,</div>
          <div class="modal-header-message">How can we help you today?</div>
        </template>
      </div>
      <div class="iframe-scroll-wrapper">
        <iframe :style="`height: ${height}px`" :src="`${formsUrl}/${lang}/support?product=${$props.product}`" scrolling="no" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { vOnClickOutside } from '@vueuse/components'

const props = defineProps({
  product: String,
  lang: String,
});

// @ts-ignore
const formsUrl = FORMS_URL;

const lang = props.lang;
const tip = lang === 'fr' ? "Bienvenue, comment pouvons-nous vous aider aujourd'hui?" : 'Welcome, how can we help you today?';
const feedbackVisible = ref(false);
const height = ref(0);
const modalHeaderHeight = ref(100);

onMounted(() => {
  window.addEventListener('message', (e) => {
    if (e.data.id != 'mentor-forms') {
      return;
    }
    height.value = e.data.height;
  });
});

function toggle() {
  feedbackVisible.value = !feedbackVisible.value;
}

const onClickOutside = () => {
  feedbackVisible.value = false
}

</script>

<style lang="scss">
body #page-footer.page-footer {
  padding: 5vh 5vw calc(2.5vh + 40px) 5vw;
}
</style>

<style lang="scss" scoped>
@use 'sass:math';

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@mixin font-tracking($font-size) {
  $z: strip-unit($font-size);
  $a: -0.075;
  $b: 0.1689;
  $c: -0.0677;
  $E: 2.71828;
  $tracking: $a + ($b * math.pow($E, ($c * $z)));

  font-size: $z * 1px;
  letter-spacing: $tracking * 1em;
}

#feedback {

  --blue-color: rgb(0,149,255);

  .floating-actions {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    bottom: 20px;
    margin-top: 20px;

    > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .floating-button {
      padding: 0;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      pointer-events: all;
      box-shadow: 0 1px 1px rgba(#000, 0.075), 0 5px 5px rgba(#000, 0.0375), 0 10px 10px rgba(#000, 0.0375);

      * {
        pointer-events: none;
      }

      svg {
        fill: #ffffff;
      }

      &.floating-button-small {
        width: 44px;
        height: 44px;
        margin-top: 6px;
        margin-bottom: 6px;
      }

      &:hover {
        &:before,
        &:after {
          opacity: 1;
        }
      }

      .material-icons {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
      }

      &:before,
      &:after {
        opacity: 0;
        transition: opacity 125ms;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 100%;
        right: 20px;
        margin-bottom: 3px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid var(--blue-color);
        pointer-events: none;
      }

      &:after {
        content: attr(data-tip);
        position: absolute;
        bottom: 100%;
        right: 0;
        margin-bottom: 10px;
        width: 90vw;
        max-width: 300px;
        padding: 10px;
        border-radius: 4px;
        color: #fff;
        background: var(--blue-color);
        font-weight: 700;
        @include font-tracking(12);
        pointer-events: none;
        white-space: normal;
      }

      &.floating-button-small {
        &:before {
          right: 14px;
          margin-bottom: 9px;
        }

        &:after {
          margin-bottom: 16px;
        }
      }
    }
  }

  position: fixed;
  z-index: 10000;
  right: 50px;
  bottom: 20px;
  @media (max-width: 999px) {
    right: 5vw;
  }
  #feedback-modal {
    position: absolute;
    right: 0;
    bottom: 60px;
    width: 320px;
    max-width: 90vw;
    max-height: calc(100vh - 60px - var(--form-nav-height, 0px));
    display: flex;
    flex-direction: column;
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 1px 1px rgba(#000, 0.075), 0 5px 5px rgba(#000, 0.0375), 0 10px 10px rgba(#000, 0.0375);
    opacity: 0;
    transform: scale(0.6);
    pointer-events: none;
    transition: opacity 300ms, transform 100ms 200ms cubic-bezier(0,1.5,.25,1);

    .modal-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 50px;
      padding: 24px;
      color: #fff;
      background: var(--blue-color);
      height: 100px;
      .modal-header-heading {
        @include font-tracking(16);
        font-weight: 700;
        line-height: 1.1;
      }
      .modal-header-message {
        @include font-tracking(12);
        line-height: 1.2;
        opacity: 0.875;
        margin-top: 0.5em;
      }
    }
    .iframe-scroll-wrapper {
      overflow-y: auto;
      scrollbar-gutter: stable;
      margin-bottom: -10px;
      iframe {
        border: none;
        margin: 0;
        padding: 0;
        width: 100%;
      }
    }
  }
  &.active {
    #feedback-modal {
      opacity: 1;
      transform: scale(1);
      pointer-events: all;
      transition: opacity 300ms, transform 500ms cubic-bezier(0,1.5,.25,1);
    }
    .floating-button:before,
    .floating-button:after {
      display: none;
    }
  }
  button {
    height: 56px;
    width: 56px;
    background-color: var(--blue-color);
    border-radius: 50%;
    border: 0;
    &:hover {
      background-color: rgb(0,97,166);
    }
  }
}
</style>
