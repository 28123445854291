import 'regenerator-runtime/runtime';
import 'core-js/stable';

import { createApp } from 'vue';
import FeedbackComponent from '../lib/mentorkit/FeedbackComponent.vue';

document.addEventListener("DOMContentLoaded", () => {
  const lang = document.querySelector("html").getAttribute("lang") === "fr-ca" ? "fr" : "en";
  const feedbackComponent = createApp(FeedbackComponent, {
    product: 'mentor-orientation',
    lang: lang,
  });
  feedbackComponent.mount('#feedback-component-app');
});
